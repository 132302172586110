import { Injectable } from '@angular/core';
import { SolutionsRequestQuery } from '../classes/default/queries/solutions.request.query';
import { SolutionCategoriesRequestQuery } from '../classes/category/queries/solution.categories.request.query';
import {
  SolutionCategoryFetchAllPayload,
  SolutionFetchAllPayload,
} from '../types/types';

@Injectable({
  providedIn: 'root',
})
export class SolutionService {
  public get solutionsQuery(): SolutionsRequestQuery {
    return this._solutionsQuery;
  }

  public get categoriesQuery(): SolutionCategoriesRequestQuery {
    return this._categoriesQuery;
  }

  public constructor(
    private readonly _solutionsQuery: SolutionsRequestQuery,
    private readonly _categoriesQuery: SolutionCategoriesRequestQuery,
  ) {}

  public fetchAll(payload?: SolutionFetchAllPayload): void {
    const _payload = payload ? { payload: payload } : undefined;

    this._solutionsQuery.watchQuery(_payload);
  }

  public fetchAllCategories(payload?: SolutionCategoryFetchAllPayload): void {
    const _payload = payload ? { payload: payload } : undefined;

    this._categoriesQuery.watchQuery(_payload);
  }
}
