import {
  DashboardMenuItems,
  DashboardViewServiceInterface,
  DashboardGroupsMenuItems,
} from '@ckmk/angular';
import { environment } from '../../../../../environments/environment';
import {
  faBoxOpen,
  faHandshakeAngle,
  faLightbulb,
} from '@fortawesome/free-solid-svg-icons';

export class DashboardViewService implements DashboardViewServiceInterface {
  public getSiteName(): string {
    return environment.SITE_NAME;
  }

  public getLogo(): string {
    return '/img/logo_favicon.png';
  }

  public generalNavItems(): DashboardMenuItems | Promise<DashboardMenuItems> {
    return [];
  }

  public itemsNavItems(): DashboardMenuItems | Promise<DashboardMenuItems> {
    return [];
  }

  public settingsNavItems(): DashboardMenuItems | Promise<DashboardMenuItems> {
    return [];
  }

  public groupNavItems():
    | DashboardGroupsMenuItems
    | Promise<DashboardGroupsMenuItems> {
    return [
      {
        name: 'solution',
        label: 'Solutions',
        children: [
          {
            name: 'solution-categories',
            label: 'Categories',
            path: 'solutions/categories/list',
            icon: faBoxOpen,
            guard: {
              permissions: ['PERMISSION_READ_SOLUTION_CATEGORY'],
            },
          },
          {
            name: 'solutions',
            label: 'Solutions',
            path: 'solutions/list',
            icon: faLightbulb,
            guard: {
              permissions: ['PERMISSION_READ_SOLUTION'],
            },
          },
        ],
      },
      {
        name: 'service',
        label: 'Services',
        children: [
          {
            name: 'service-categories',
            label: 'Categories',
            path: 'services/categories/list',
            icon: faBoxOpen,
            guard: {
              permissions: ['PERMISSION_READ_SERVICE_CATEGORY'],
            },
          },
          {
            name: 'services',
            label: 'Services',
            path: 'services/list',
            icon: faHandshakeAngle,
            guard: {
              permissions: ['PERMISSION_READ_SERVICE'],
            },
          },
        ],
      },
    ];
  }
}
