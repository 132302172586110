import { Route } from '@angular/router';
import { ServiceListComponent } from '../components/default/service-list/service-list.component';
import { ServiceShowComponent } from '../components/default/service-show/service-show.component';
import { ServiceManageComponent } from '../components/default/service-manage/service-manage.component';
import { CategoryListComponent } from '../components/category/category-list/category-list.component';
import { CategoryShowComponent } from '../components/category/category-show/category-show.component';
import { CategoryManageComponent } from '../components/category/category-manage/category-manage.component';

export default {
  path: 'services',
  children: [
    {
      path: '',
      redirectTo: 'list',
      pathMatch: 'full',
    },
    {
      path: 'list',
      component: ServiceListComponent,
      data: {
        permissions: ['PERMISSION_READ_SERVICE'],
      },
    },
    {
      path: 'show/:service',
      component: ServiceShowComponent,
      data: {
        permissions: ['PERMISSION_READ_SERVICE'],
      },
    },
    {
      path: 'create',
      component: ServiceManageComponent,
      data: {
        permissions: ['PERMISSION_CREATE_SERVICE'],
      },
    },
    {
      path: 'update/:service',
      component: ServiceManageComponent,
      data: {
        permissions: ['PERMISSION_UPDATE_SERVICE'],
      },
    },
    {
      path: 'copy/:service',
      component: ServiceManageComponent,
      data: {
        permissions: ['PERMISSION_COPY_SERVICE'],
      },
    },
    {
      path: 'categories',
      children: [
        {
          path: '',
          redirectTo: 'list',
          pathMatch: 'full',
        },
        {
          path: 'list',
          component: CategoryListComponent,
          data: {
            permissions: ['PERMISSION_READ_SERVICE_CATEGORY'],
          },
        },
        {
          path: 'show/:service',
          component: CategoryShowComponent,
          data: {
            permissions: ['PERMISSION_READ_SERVICE_CATEGORY'],
          },
        },
        {
          path: 'create',
          component: CategoryManageComponent,
          data: {
            permissions: ['PERMISSION_CREATE_SERVICE_CATEGORY'],
          },
        },
        {
          path: 'update/:service',
          component: CategoryManageComponent,
          data: {
            permissions: ['PERMISSION_UPDATE_SERVICE_CATEGORY'],
          },
        },
        {
          path: 'copy/:service',
          component: CategoryManageComponent,
          data: {
            permissions: ['PERMISSION_COPY_SERVICE_CATEGORY'],
          },
        },
        {
          path: '**',
          redirectTo: 'list',
          pathMatch: 'full',
        },
      ],
    },
    {
      path: '**',
      redirectTo: 'list',
      pathMatch: 'full',
    },
  ],
} as Route;
