import { Component } from '@angular/core';

@Component({
  selector: 'app-solution-show',
  imports: [],
  templateUrl: './solution-show.component.html',
  styleUrl: './solution-show.component.scss'
})
export class SolutionShowComponent {

}
