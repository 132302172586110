import { Injectable } from '@angular/core';
import { AbstractGraphqlRequest, PaginationResult } from '@ckmk/angular';
import { SolutionCategoriesResult } from '../../../types/types';
import { ApolloQueryResult } from '@apollo/client';
import { MutationResult, TypedDocumentNode } from 'apollo-angular';
import { SolutionCategoriesQuery } from '../../../queries/solution.categories.query';
import { SolutionCategoryModel } from '../model/solution.category.model';

@Injectable({
  providedIn: 'root',
})
export class SolutionCategoriesRequestQuery extends AbstractGraphqlRequest<SolutionCategoriesResult> {
  protected override parseQueryResult(
    response: ApolloQueryResult<any> | null,
  ): any {
    return null;
  }

  protected override getQuery(): TypedDocumentNode<any, any> {
    return SolutionCategoriesQuery;
  }

  protected override onQuery({ loading, data }: ApolloQueryResult<any>) {
    if (loading) {
      this.result$.next({
        result: this.result$.value?.result || [],
        loading: true,
      });
    } else {
      let { pagination, result } = data.findAllSolutionCategories;

      if (pagination) {
        pagination = new PaginationResult(pagination);
      }

      result = result.map((grp: any) => new SolutionCategoryModel(grp));

      this.result$.next({
        pagination,
        result,
        loading: false,
      });
    }
  }

  protected override onMutation(response: MutationResult<any>) {}
}
