<ng-container>
  <dashboard-header
    [viewName]="'services'"
    [subTitle]="'List of' | trans: {
      items: ('Services' | trans).toLowerCase(),
    }"
  />

  <datatable
    [name]="'services-list'"
    [type]="datatableType"
    [data]="services"
    [itemsName]="'Services'"
    [loading]="loading"
    [pagination]="pagination"
    (onPayload)="onPayload($event)"
    (onEvent)="onEvent($event)"
  />
</ng-container>
