import { gql } from 'apollo-angular';

export const ServiceCategoriesQuery = gql`
  query findAllServiceCategories($payload: ServiceCategoryRequestPayloadInput) {
    findAllServiceCategories(payload: $payload) {
      pagination {
        hasNext
        hasPrevious
        limit
        page
        total
        totalPage
      }
      result {
        id
        cover {
          id
          name
          title
          description
          frontUrls
        }
        slug
        title
        description
        shortDescription
        active
        createdAt
        updatedAt
        deletedAt
        createdBy
        updatedBy
      }
    }
  }
`;
