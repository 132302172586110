import { Component } from '@angular/core';

@Component({
  selector: 'app-category-show',
  imports: [],
  templateUrl: './category-show.component.html',
  styleUrl: './category-show.component.scss'
})
export class CategoryShowComponent {

}
