import { Route } from '@angular/router';
import { SolutionListComponent } from '../components/default/solution-list/solution-list.component';
import { SolutionShowComponent } from '../components/default/solution-show/solution-show.component';
import { SolutionManageComponent } from '../components/default/solution-manage/solution-manage.component';
import { CategoryListComponent } from '../components/category/category-list/category-list.component';
import { CategoryShowComponent } from '../components/category/category-show/category-show.component';
import { CategoryManageComponent } from '../components/category/category-manage/category-manage.component';

export default {
  path: 'solutions',
  children: [
    {
      path: '',
      redirectTo: 'list',
      pathMatch: 'full',
    },
    {
      path: 'list',
      component: SolutionListComponent,
      data: {
        permissions: ['PERMISSION_READ_SOLUTION'],
      },
    },
    {
      path: 'show/:solution',
      component: SolutionShowComponent,
      data: {
        permissions: ['PERMISSION_READ_SOLUTION'],
      },
    },
    {
      path: 'create',
      component: SolutionManageComponent,
      data: {
        permissions: ['PERMISSION_CREATE_SOLUTION'],
      },
    },
    {
      path: 'update/:solution',
      component: SolutionManageComponent,
      data: {
        permissions: ['PERMISSION_UPDATE_SOLUTION'],
      },
    },
    {
      path: 'copy/:solution',
      component: SolutionManageComponent,
      data: {
        permissions: ['PERMISSION_COPY_SOLUTION'],
      },
    },
    {
      path: 'categories',
      children: [
        {
          path: '',
          redirectTo: 'list',
          pathMatch: 'full',
        },
        {
          path: 'list',
          component: CategoryListComponent,
          data: {
            permissions: ['PERMISSION_READ_SOLUTION_CATEGORY'],
          },
        },
        {
          path: 'show/:solution',
          component: CategoryShowComponent,
          data: {
            permissions: ['PERMISSION_READ_SOLUTION_CATEGORY'],
          },
        },
        {
          path: 'create',
          component: CategoryManageComponent,
          data: {
            permissions: ['PERMISSION_CREATE_SOLUTION_CATEGORY'],
          },
        },
        {
          path: 'update/:solution',
          component: CategoryManageComponent,
          data: {
            permissions: ['PERMISSION_UPDATE_SOLUTION_CATEGORY'],
          },
        },
        {
          path: 'copy/:solution',
          component: CategoryManageComponent,
          data: {
            permissions: ['PERMISSION_COPY_SOLUTION_CATEGORY'],
          },
        },
        {
          path: '**',
          redirectTo: 'list',
          pathMatch: 'full',
        },
      ],
    },
    {
      path: '**',
      redirectTo: 'list',
      pathMatch: 'full',
    },
  ],
} as Route;
