import { Injectable } from '@angular/core';
import { AbstractGraphqlRequest, PaginationResult } from '@ckmk/angular';
import { SolutionsResult } from '../../../types/types';
import { ApolloQueryResult } from '@apollo/client';
import { MutationResult, TypedDocumentNode } from 'apollo-angular';
import { SolutionModel } from '../model/solution.model';
import { SolutionsQuery } from '../../../queries/solutions.query';

@Injectable({
  providedIn: 'root',
})
export class SolutionsRequestQuery extends AbstractGraphqlRequest<SolutionsResult> {
  protected override parseQueryResult(
    response: ApolloQueryResult<any> | null,
  ): any {
    return null;
  }

  protected override getQuery(): TypedDocumentNode<any, any> {
    return SolutionsQuery;
  }

  protected override onQuery({ loading, data }: ApolloQueryResult<any>) {
    if (loading) {
      this.result$.next({
        result: this.result$.value?.result || [],
        loading: true,
      });
    } else {
      let { pagination, result } = data.findAllSolutions;

      if (pagination) {
        pagination = new PaginationResult(pagination);
      }

      result = result.map((grp: any) => new SolutionModel(grp));

      this.result$.next({
        pagination,
        result,
        loading: false,
      });
    }
  }

  protected override onMutation(response: MutationResult<any>) {}
}
