import { Injectable } from '@angular/core';
import { ServicesRequestQuery } from '../classes/default/queries/services.request.query';
import { ServiceCategoriesRequestQuery } from '../classes/category/queries/service.categories.request.query';
import {
  ServiceCategoryFetchAllPayload,
  ServiceFetchAllPayload,
} from '../types/types';

@Injectable({
  providedIn: 'root',
})
export class ServiceService {
  public get servicesQuery(): ServicesRequestQuery {
    return this._servicesQuery;
  }

  public get categoriesQuery(): ServiceCategoriesRequestQuery {
    return this._categoriesQuery;
  }

  public constructor(
    private readonly _servicesQuery: ServicesRequestQuery,
    private readonly _categoriesQuery: ServiceCategoriesRequestQuery,
  ) {}

  public fetchAll(payload?: ServiceFetchAllPayload): void {
    const _payload = payload ? { payload: payload } : undefined;

    this._servicesQuery.watchQuery(_payload);
  }

  public fetchAllCategories(payload?: ServiceCategoryFetchAllPayload): void {
    const _payload = payload ? { payload: payload } : undefined;

    this._categoriesQuery.watchQuery(_payload);
  }
}
