import {
  AbstractDefaultPropsModel,
  TranslationColumnValueModel,
  UploadModel,
} from '@ckmk/angular';
import { SolutionCategoryModel } from '../../category/model/solution.category.model';

export class SolutionModel extends AbstractDefaultPropsModel {
  /**
   * The solution id
   * @protected
   */
  protected _id?: string;
  public get id(): string | undefined {
    return this._id;
  }
  public set id(id: string | undefined) {
    this._id = id;
  }

  /**
   * The solution category
   * @protected
   */
  protected _category?: SolutionCategoryModel;
  public get category(): SolutionCategoryModel | undefined {
    return this._category;
  }
  public set category(category: any) {
    this._category = this.toInstance(SolutionCategoryModel, category);
  }

  /**
   * The solution cover
   * @protected
   */
  protected _cover?: UploadModel;
  public get cover(): UploadModel | undefined {
    return this._cover;
  }
  public set cover(cover: any) {
    this._cover = this.toInstance(UploadModel, cover);
  }

  /**
   * The solution picture
   * @protected
   */
  protected _picture?: UploadModel;
  public get picture(): UploadModel | undefined {
    return this._picture;
  }
  public set picture(picture: any) {
    this._picture = this.toInstance(UploadModel, picture);
  }

  /**
   * The solution slug
   * @protected
   */
  protected _slug?: string;
  public get slug(): string | undefined {
    return this._slug;
  }
  public set slug(slug: string | undefined) {
    this._slug = slug;
  }

  /**
   * The solution title
   * @protected
   */
  protected _title?: TranslationColumnValueModel;
  public get title(): TranslationColumnValueModel | undefined {
    return this._title;
  }
  public set title(title: any | undefined) {
    this._title = this.toInstance(TranslationColumnValueModel, title);
  }

  /**
   * The solution description
   * @protected
   */
  protected _description?: TranslationColumnValueModel;
  public get description(): TranslationColumnValueModel | undefined {
    return this._description;
  }
  public set description(description: any | undefined) {
    this._description = this.toInstance(
      TranslationColumnValueModel,
      description,
    );
  }

  /**
   * The solution short description
   * @protected
   */
  protected _shortDescription?: TranslationColumnValueModel;
  public get shortDescription(): TranslationColumnValueModel | undefined {
    return this._shortDescription;
  }
  public set shortDescription(shortDescription: any | undefined) {
    this._shortDescription = this.toInstance(
      TranslationColumnValueModel,
      shortDescription,
    );
  }

  public constructor(data?: any) {
    super();

    Object.assign(this, data || {});
  }
}
