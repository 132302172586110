import {
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ServiceModel } from '../../../classes/default/model/service.model';
import {
  ColumnActionEventInterface,
  DashboardModule,
  DatatableModule,
  PaginationResult,
  TranslationModule,
} from '@ckmk/angular';
import { ServiceFetchAllPayload } from '../../../types/types';
import { ServiceService } from '../../../services/service.service';
import { ServiceDatatableType } from '../../../classes/default/datatable/service.datatable.type';

@Component({
  selector: 'app-service-list',
  imports: [DatatableModule, DashboardModule, TranslationModule],
  templateUrl: './service-list.component.html',
  styleUrl: './service-list.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ServiceListComponent implements OnInit, OnChanges, OnDestroy {
  protected services: ServiceModel[] = [];

  protected pagination?: PaginationResult;

  protected loading: boolean = false;

  public constructor(
    private readonly _self: ElementRef,
    private readonly _service: ServiceService,
    protected readonly datatableType: ServiceDatatableType,
  ) {}

  public async ngOnInit() {
    this._service.servicesQuery.onQuerySuccess((response) => {
      if (response) {
        const { result: services, pagination, loading } = response;

        if (pagination) {
          this.pagination = pagination;
        }
        this.services = services;
        this.loading = loading;
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges) {}

  public ngOnDestroy() {}

  protected onPayload(payload?: ServiceFetchAllPayload): void {
    this._service.fetchAll(payload);
  }

  protected onEvent(event: ColumnActionEventInterface) {
    event.run('show', () => {
      console.log('Run show');
    });

    event.run('update', () => {
      console.log('Run update');
    });

    event.run('copy', () => {
      console.log('Run copy');
    });

    event.run('delete', () => {
      console.log('Run delete');
    });

    event.run('restore', () => {
      console.log('Run restore');
    });
  }
}
