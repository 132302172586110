import { Component } from '@angular/core';

@Component({
  selector: 'app-category-manage',
  imports: [],
  templateUrl: './category-manage.component.html',
  styleUrl: './category-manage.component.scss'
})
export class CategoryManageComponent {

}
