import { Injectable } from '@angular/core';
import { AbstractGraphqlRequest, PaginationResult } from '@ckmk/angular';
import { ServicesResult } from '../../../types/types';
import { ApolloQueryResult } from '@apollo/client';
import { MutationResult, TypedDocumentNode } from 'apollo-angular';
import { ServiceModel } from '../model/service.model';
import { ServicesQuery } from '../../../queries/services.query';

@Injectable({
  providedIn: 'root',
})
export class ServicesRequestQuery extends AbstractGraphqlRequest<ServicesResult> {
  protected override parseQueryResult(
    response: ApolloQueryResult<any> | null,
  ): any {
    return null;
  }

  protected override getQuery(): TypedDocumentNode<any, any> {
    return ServicesQuery;
  }

  protected override onQuery({ loading, data }: ApolloQueryResult<any>) {
    if (loading) {
      this.result$.next({
        result: this.result$.value?.result || [],
        loading: true,
      });
    } else {
      let { pagination, result } = data.findAllServices;

      if (pagination) {
        pagination = new PaginationResult(pagination);
      }

      result = result.map((grp: any) => new ServiceModel(grp));

      console.log(result);

      this.result$.next({
        pagination,
        result,
        loading: false,
      });
    }
  }

  protected override onMutation(response: MutationResult<any>) {}
}
